<template>
    <Page icon="mdi-filmstrip-box-multiple" :title="form.name" :progress="progress" :error="error" @reload="load()">
        <ActionBar :updateTime="updateTime">
            <v-btn :loading="saveProgress" :disabled="saveProgress || dangerProgress" color="primary" @click="save()"> 
                <v-icon left>mdi-content-save-outline</v-icon> {{$t('save')}} 
            </v-btn>
        </ActionBar>
        
        <Card :title="$t('video.update.title')" class="mt-3">
            <v-text-field
                :label="$t('video.form.name')"
                v-model="form.name"
                :error-messages="errors.name"
                :hide-details="!errors.name"
                :disabled="saveProgress || dangerProgress"
                @change="validate('name')"
                outlined
                prepend-inner-icon="mdi-filmstrip-box-multiple"
            />

            <v-text-field
                class="mt-3"
                :label="$t('video.form.info')"
                v-model="form.info"
                :error-messages="errors.info"
                :hide-details="!errors.info"
                :disabled="saveProgress || dangerProgress"
                @change="validate('info')"
                outlined
                prepend-inner-icon="mdi-clipboard-text-outline"
            />

            <v-text-field
                class="mt-3"
                :value="videourl"
                :label="$t('video.form.url')"
                :hide-details="true"
                append-icon="mdi-content-copy"
                @click:append="copyToClipboard(videourl)"
                readonly
                outlined
                prepend-inner-icon="mdi-web"
            />

            <div class="text-center" v-if="file">   
                <video width="70%" controls class="mt-6">
                    <source :src="videourl" type="video/mp4">
                </video>
            </div>
        </Card>

        <Card :title="$t('video.update.upload.title')" class="mt-3" :saveProgress="uploadProgress">
            <v-file-input
                v-model="formUpload.file"
                @change="selectFile"
                :hide-details="false"
                :error-messages="errors.file"
                :disabled="uploadProgress"
                :loading="uploadProgress"
                prepend-icon=""
                prepend-inner-icon="mdi-filmstrip"
                accept="video/mp4"
                :label="$t('video.form.file')"
                :hint="$t('video.form.filehint')"
                persistent-hint
                outlined
                show-size
            />

            <template v-slot:actions>
                <v-btn 
                    :loading="uploadProgress"
                    :disabled="!formUpload.file || uploadProgress"
                    @click="upload()"
                    class="primary">
                        <v-icon left>mdi-upload</v-icon> {{ $t("video.update.upload.save") }}
                </v-btn>
            </template>
        </Card>

        <DangerZone
            @btnClick="beforeDangerZoneAction()"
            v-model="dialogDangerItem"
            :data="[
                {
                    itemKey: 'changeStatus',
                    itemName: $t('video.danger_zone.change_status'),
                    itemInfo: $t('video.danger_zone.change_status_info') + ' ',
                    itemInfoGreen: (form.enabled ? $t('active') : null),
                    itemInfoRed: (form.enabled ? null : $t('inactive')),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'changeStatus' ? dangerProgress : false)
                },

                {
                    itemKey: 'delete',
                    itemName: $t('video.danger_zone.delete'),
                    itemInfo: $t('video.danger_zone.delete_info'),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'delete' ? dangerProgress : false)
                }
            ]"
            :disabled="saveProgress || dangerProgress"
        />

        <DangerZoneConfirm
            v-if="dialogDanger"
            :title="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('video.danger_zone.change_status');
                    break;
                case 'delete':
                    return $t('video.danger_zone.delete');
            }}"
            :description="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('video.danger_zone.dialog.change_status_desc');
                    break;
                case 'delete':
                    return $t('video.danger_zone.dialog.delete_desc');
            }}"
            :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return (form.enabled ? $t('inactive') : $t('active'));
                    break;
                case 'delete':
                    return form.name;
            }}"
            :checkText="() => form.name"
            :dependenciesData="dependenciesData" 
            :open="dialogDanger"
            :save="dialogDangerItem.itemName"
            :saveProgress="dangerDialogSaveProgress"
            @cancel="dialogDanger = false" 
            @save="dangerZoneAction(dialogDangerItem)"
        />

        <v-snackbar
            v-model="copyAlert"
            timeout="1000"
            min-height="40"
            min-width="220"
            height="40"
            width="220"
            color="white secondary--text"
            content-class="d-flex justify-center align-center pr-0 pl-0 pt-0 pb-0 mr-0 ml-0"
        > 
            {{$t('copied_to_clipboard')}} 
        </v-snackbar>

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import Card from "./components/Card.vue";
import ActionBar from "./components/ActionBar.vue";
import DangerZone from "./components/DangerZone.vue";
import DangerZoneConfirm from "./dialogs/DangerZoneConfirm.vue";
import validator from "../plugins/validator";
import * as videoValidation from "../api/video/validation";
import copy from 'copy-to-clipboard';
import SaveSnackBar from "./components/SaveSnackBar.vue";

export default {
    name: "Viedo",
    components: {
        Page,
        Card,
        ActionBar,
        DangerZone,
        DangerZoneConfirm,
        SaveSnackBar
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.formUpload.id = this.$route.params.id;
        this.load();
    },

    data: () => ({
        file: null,
        copyAlert: false,

        error: {
            type: null,
            msg: null,
            code: null
        },

        form: {
            id: null,
            name: null,
            info: null,
            enabled: null
        },

        formUpload: {
            id: null,
            file: null,
        },

        errors: {
            name: null,
            info: null,
            file: null
        },

        dialogDanger: false,
        dialogDangerItem: null,
        dependenciesData: null,

        updateTime: null,
        progress: true,
        saveProgress: false,
        uploadProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,
        save_snackbar: false,
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        },

        videourl(){
            if(!this.file) return;
            return this.$store.state.config.api.media + this.file.name
        }
    },

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                videoValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }

            const res = await this.$store.dispatch("api/video/get", { id: this.form.id });

            if(res.status == 200) {
                this.form.name = res.data.name;
                this.form.info = res.data.info;
                this.file = res.data.file;
                this.form.enabled = res.data.enabled;
                this.updateTime = res.data.updateTime ? new Date(res.data.updateTime) : null;
            }else{
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }
            
            this.progress = false;

            return;
        },

        async save() {
            if (!this.validate()) return;

            this.saveProgress = true;
            await this.$store.dispatch("api/video/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async upload() {
            if(!this.formUpload.file) return;

            const formData = new FormData();
            formData.append('id', this.formUpload.id);
            formData.append('file', this.formUpload.file);

            this.uploadProgress = true;
            const res = await this.$store.dispatch("api/video/upload", formData);

            if(res.status == 200){
                this.formUpload.file = null;
                this.errors.file = null;
            }

            if(res.status == 400){
                this.errors.file = this.$t("form_errors.file."+res.data.error.code);
            }

            this.uploadProgress = false;

            this.load();
        },

        selectFile(file){
            this.errors.file = null;
            this.formUpload.file = file;
        },

        copyToClipboard(text) {
            this.copyAlert = copy(text);
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dependenciesData = null;
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "delete":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;
                    const res = await this.$store.dispatch("api/video/delete", { id: this.form.id });
                    if (res.status==405) {
                        this.dependenciesData = res.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    } else if (res.status==200) {
                        this.$router.push({ name: 'videos' })
                    } else {
                        this.error = {
                            type: "network",
                            msg: res.data.error.code,
                            code: res.status
                        }
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                    }
                    break;
                case "changeStatus":
                    this.dangerDialogSaveProgress = true;
                    let tempForm = Object.assign({}, this.form);
                    tempForm.enabled = !this.form.enabled;
                    await this.$store.dispatch("api/video/update", tempForm);
                    await this.load();
                    this.dialogDanger = false;
                    this.dangerDialogSaveProgress = false;
                    break;
            }
        }
    }
};
</script>
